import { ro } from 'vuetify/lib/locale'

export default {
  ...ro,
  Name: '_Name',
  generic: {
    salveaza: 'Salveaza',
    anuleaza: 'Anuleaza',
    voucher: 'Voucher',
    inchide: 'inchide',
    toate: 'Toate',
    zileInUrma: 'precedente',
    nuExistaVoturi: 'Nu exista destule voturi',
    cumparaBileteLa: 'Cumpara bilete la',
    ora: 'ora',
    bileteDeLa: 'De la ',
    urmatoarelePatruZile: 'urmatoare',
    reprezentatieTrecuta: 'Reprezentatie trecuta',
    months: {
      January: 'Ianuarie',
      February: 'Februarie',
      March: 'Martie',
      April: 'Aprilie',
      May: 'Mai',
      June: 'Iunie',
      July: 'Iulie',
      August: 'August',
      September: 'Septembrie',
      October: 'Octombrie',
      November: 'Noiembrie',
      December: 'Decembrie'
    },
    veziToataZiua: 'Extinde Ziua',
    locuriLibere: ' locuri libere',
    dow: {
      1: 'Luni',
      2: 'Marti',
      3: 'Miercuri',
      4: 'Joi',
      5: 'Vineri',
      6: 'Sambata',
      0: 'Duminica'
    }
  },
  site: {
    cookiePolicy: 'Acest site foloseste cookie-uri esentiale pentru functionarea corecta. Navigand in continuare, va exprimati acordul asupra folosirii acestora.',
    meta: {
      description: 'Bilete la teatru pe www.mySTAGE.ro',
      title: 'mySTAGE',
      siteName: 'www.mySTAGE.ro'
    },
    header: {
      preferences: 'Preferinte'
    },
    account: {
      disconnect: 'Deconecteza-te'
    },
    notFoundTitle: 'Eroare 404.',
    notFoundText: 'Nu pot gasi aceasta pagina.',
    goBack: 'Intoarce-te',
    menu: {
      signIn: 'Cont',
      vindeCuNoi: 'Vinde',
      vouchere: 'Carduri Cadou',
      ajutor: 'Ajutor',
      signOut: 'Deconecteaza-te',
      profil: 'Profilul meu',
      bilete: 'Portofel'
    },
    home: 'Acasa',
    category: {
      catName_1: 'Noi',
      catName_2: 'Top Vandute',
      catName_3: 'In Weekend',
      catName_4: 'Recomandari',
      catName_5: 'Populare',
      catName_6: 'Top Cotate',
      catName_7: 'Trending',
      catName_8: 'Trending',

      catIcon_1: 'recent_actors',
      catIcon_2: 'trending_up',
      catIcon_3: 'weekend',
      catIcon_4: 'recommend',
      catIcon_5: 'volunteer_activism',
      catIcon_6: 'star_rate',
      catIcon_7: 'trending_up',
      catIcon_8: 'trending_up'

    },
    footer: {
      sugestii: 'Sugestii si sesizari',
      ajutor: 'Ajutor / FAQ',
      contact: 'Contact',
      termeni: 'Termeni si conditii',
      telefonulConsumatorului: 'Telefonul Consumatorilor - 0219551',
      anpc: 'www.anpc.gov.ro',
      comisariat: 'Comisariatul pentru Protecţia Consumatorilor al Municipiului Bucuresti - Str. Transilvaniei, 2, Bucuresti-Sector 1, Bucuresti, 10796, București, Romania'

    },
    lang: {
      ro: 'Romana',
      en: 'Engleza'
    },
    searchHeader: 'Cauta spectacole, actori, teatre sau regizori'
  },
  event: {
    myStageQueueTitle: 'Esti in asteptare',
    explicatieQueue: 'Momentan, esti in asteptare pentru a acesa site-ul nostru. In cel mai scurt timp vei fi redirectionat catre spectacolele tale preferate. Iti multumim pentru rabdare si te rugam sa nu dai refresh la pagina pentru a-ti pastra prioritatea.',
    maiSunt: 'Mai sunt',
    maiSuntSg: 'Mai este',
    persoaneInainteaTa: 'persoane in fata ta.',
    persoaneInainteaTaSg: 'persoana in fata ta.',
    aiIntratInQueueLa: 'Ai intrat in coada la ora',
    durataMedieDeAsteptare: 'Durata medie de asteptare este de ',
    aiAjunsLaCapat: 'Asteptarea s-a incheiat!',
    teVomRedirectionaAutomat: 'Vei fi redirectionat in cateva momente pe mystage.ro. In cazul in care nu ai fost redirectionat, te rugam sa accesezi link-ul de mai jos:',
    potiIncercaSaSariCoada: 'Poti sa reincerci sa accesezi site-ul mai rapid prin link-ul de mai jos:',
    clickAici: 'www.mySTAGE.ro',
    minute: 'minute',
    secunde: 'secunde'
  }

}
