<template>
  <v-container class="main" fluid >
      <v-card :loading="true" class="mt-2 flex mx-auto" elevation="10" max-width="600px" v-if="(queue.startTime !== null && queue.myPlace > 1 )">
        <v-card-text class="text-center">
          <h1 class="mt-4">{{$vuetify.lang.t('$vuetify.event.myStageQueueTitle')}} </h1>
        </v-card-text>
        <v-card-text class="justify-center text-justify font-italic">
          <h3 class="mx-8">{{$vuetify.lang.t('$vuetify.event.explicatieQueue')}} </h3>
        </v-card-text>
        <v-card-title v-if="queue.myPlace > 1" >
          <v-progress-linear
              color="primary"
              buffer-value="0"
              :value="queue.loadValue"
              stream
              height="32"
              rounded
          >
          </v-progress-linear>
        </v-card-title>
        <v-card-text>
          <v-card elevation="0" color="primary text-center my-8 mx-auto flex">
            <v-row dense class="white--text py-2 mx-auto">
              <v-col cols="12">
                <h2 v-if="queue.myPlace > 2">{{$vuetify.lang.t('$vuetify.event.maiSunt')}} {{ queue.myPlace - 1 }} {{$vuetify.lang.t('$vuetify.event.persoaneInainteaTa')}}</h2>
                <h2 v-else>{{$vuetify.lang.t('$vuetify.event.maiSuntSg')}} {{ queue.myPlace - 1 }} {{$vuetify.lang.t('$vuetify.event.persoaneInainteaTaSg')}}</h2>
              </v-col>
              <v-col cols="12">
                <h3 >{{$vuetify.lang.t('$vuetify.event.aiIntratInQueueLa')}} {{queue.startTime.format('HH:mm:ss')}} </h3>
              </v-col>
              <v-col cols="12">
                <h3>{{$vuetify.lang.t('$vuetify.event.durataMedieDeAsteptare')}}
                  <strong v-if="queue.timeLeftNice.minute > 0">{{queue.timeLeftNice.minute}} {{$vuetify.lang.t('$vuetify.event.minute')}} </strong>
                  <strong v-if="queue.timeLeftNice.secunde">{{queue.timeLeftNice.secunde}} {{$vuetify.lang.t('$vuetify.event.secunde')}} </strong>
                </h3>
              </v-col>
              <v-col cols="12" class="white--text">
                <h4>{{$vuetify.lang.t('$vuetify.event.potiIncercaSaSariCoada')}}</h4><v-btn link color="white" @click="skipQueue()" text :href="targetUrl">{{$vuetify.lang.t('$vuetify.event.clickAici')}}</v-btn>
              </v-col>
          </v-row>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card :loading="!queue.canGo" dark class="text-center secondary flex mx-auto mt-4" elevation="10" max-width="800" v-if="queue.startTime !== null && queue.myPlace <= 1 ">
        <v-row dense class="mx-auto">
          <v-col cols="12" class="mt-2">
            <h2>{{ $vuetify.lang.t('$vuetify.event.aiAjunsLaCapat') }}</h2>
          </v-col>
          <v-col cols="12">
            <h3>{{ $vuetify.lang.t('$vuetify.event.teVomRedirectionaAutomat') }}</h3>
          </v-col>
          <v-col cols="12">
            <v-btn @click="leaveQueue()" text :href="targetUrl">{{targetUrl}}</v-btn>
          </v-col>
        </v-row>
      </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Queue',
  title: 'myStage.ro',
  components: { },
  data: function () {
    return {
      startedSiteCheck: false,
      faye: {
        myId: null,
        client: null,
        subscription: null,
        activeConnections: null
      }
    }
  },
  methods: {
    fayeSetup () {
      if (this.faye.client == null) {
        // eslint-disable-next-line no-undef
        this.faye.client = new Faye.Client('https://node.starter.ro/events/')
        this.faye.myId = null
      } else {
        this.faye.subscription.cancel()
      }
      if (this.faye.subscription !== null) {
        this.faye.client.unsubscribe(this.faye.subscription)
      }
      this.faye.subscription = this.faye.client.subscribe('/events/queue', (msg) => {
        if (this.faye.myId === null) {
          this.faye.myId = this.faye.client._dispatcher.clientId
        }
        msg.src = 'queue'
        msg.me = this.faye.client._dispatcher.clientId
        this.handleEventMessages(msg)
      })
    },
    handleEventMessages (msg) {
      switch (msg.type) {
        case 'connections':
          this.$store.commit('setConnections', msg.value)
          break
        case 'recheck':
        case 'pricechange':
        case 'prune':
          if (msg.initiator !== msg.me) {
            // prune triggered
          }
          break
        default:
          break
      }
      if (msg.type === 'connections') {
        this.faye.activeConnections = msg.value
      }
    },
    leaveQueue () {
      this.$gtag.event('leaveQueue', { method: 'Local', event_category: 'Queue' })
      window.location.href = this.$store.state.urls.forwardUrl
    },
    skipQueue () {
      this.$gtag.event('skipQueue', { method: 'Local', event_category: 'Queue' })
      window.location.href = this.$store.state.urls.forwardUrl
    },
    startChecks () {
      setInterval(
        () => {
          if (!this.$store.state.canGo) {
            this.checkSiteStatus()
          }
        },
        2000
      )
    },
    forwardClient () {
      setInterval(
        () => {
          if (this.$store.state.queue.canGo) {
            if (this.$store.state.urls.allowForward) {
              this.leaveQueue()
            }
          }
        },
        1500
      )
    },
    checkSiteStatus () {
      const target = this.$store.state.urls.checkUrl
      this.$axios.create().get(target, {}).then(
        (res) => {
          if (res.status !== 200) {
            this.$log.error('Requesting status failed')
            this.$store.commit('toggleCanGo', false)
          } else {
            this.$store.commit('toggleCanGo', true)
          }
        },
        (rej) => {
          this.$store.commit('toggleCanGo', false)
          this.$log.error('Requesting status failed')
        }
      )
    }
  },

  computed: {
    allowForward () {
      return this.$store.state.urls.allowForward
    },
    targetUrl () {
      return this.$store.state.urls.forwardUrl
    },
    queue () {
      return this.$store.state.queue
    }
  },
  created: function () {
  },
  mounted: function () {
    this.fayeSetup()
    this.$store.commit('toggleFaye')
  },
  watch: {
    'queue.myPlace' (newPlace) {
      this.$log.debug(moment().format('MM:ss') + ' ' + newPlace)
      if (newPlace < 3) {
        if (!this.startedSiteCheck) {
          this.startedSiteCheck = true
          this.startChecks()
          this.$log.debug('Started placebo checks')
        }
      }
      if (newPlace <= 1) {
        setTimeout(() => {
          this.$gtag.event('unsubscribe', { method: 'Local', event_category: 'Queue' })
          this.faye.client.unsubscribe('/events/queue')
          this.$log.debug(moment().format('MM:ss') + 'Unsub')
          this.forwardClient()
        },
        Math.floor(Math.random() * 4 + 2) * 1000)
      }
    }
  }
}
</script>
<style scoped>
.main {
  -webkit-flex: 1;
  flex: 1 1 auto;
  position: relative;
  min-width: 1px;
  max-width: 1520px;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
}
</style>
