<template>
  <v-footer
      color="secondary"
      padless
  >
    <v-row
        justify="center"
        no-gutters
        dense

    >
        <v-btn v-for="link in links"
               :key="link.title"
            color="white"
            text
            class="my-1"
        >
          {{ link.title }}
        </v-btn>
      <v-col
          class="primary darken-2 py-4 text-center white--text"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>mySTAGE.ro</strong> {{versionInfo}}
      </v-col>
      <v-col cols="12"  class="py-4 text-center white--text subtitle-2 text--darken-4" >
        <span   >
          <a href="https://www.queuecat.com" target="_blank">Powered by <i>QueueCat </i></a>
        </span>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    links: []
  }),
  methods: {
    populateLinks () {
      this.links = [

      ]
    }
  },
  mounted () {
    this.populateLinks()
  },
  computed: {
    versionInfo () {
      return `v. ${this.$store.state.versionNumber} `
    }
  }
}
</script>
