import { en } from 'vuetify/lib/locale'

export default {
  ...en,
  Name: '_Name',
  rules: {
    lengthUnder250: '_Must be under 250 characters',
    notEmpty: '_Must not be empty',
    minThreeChars: '_Minimum 3 characters',
    equalOrGreaterThan0: '_Must be equal or greater than 0',
    greaterThan0: '_Must be greater than 0',
    maxFiveKChars: '_Maximum 5000 characters',
    pctCannotBeOver100: '_Cannot be over 100%',
    'the email address is invalid': '_The Email Address is Invalid'
  }

}
