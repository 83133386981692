<template>
  <v-app>
    <v-app-bar app elevation="0" color="primary" large >
          <div>
            <router-link plain text to="/">
              <v-img v-if="height > 400"
                  alt="myStage"
                  class="logo v-size--large"
                  contain
                  src="@/assets/logo.png"
                  transition="scale-transition"
                  width="110"
              />
              <v-img v-else
                     width="32px"
                     alt="myStage"
                     class="logo v-size--large"
                     contain
                     src="@/assets/mystage-web-small.png"
                     transition="scale-transition"
                     height="32px"
              >

              </v-img>
            </router-link>
          </div>
    </v-app-bar>
    <v-main class="info">
      <router-view :key="$route.fullPath" />
    </v-main>
    <Footer></Footer>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="vindeDialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
              color="primary"
              dark
          >{{$vuetify.lang.t('$vuetify.dialog.vindeTitle')}}</v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-12">Hello world!</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="dialog.value = false"
            >{{ $vuetify.lang.t('$vuetify.generic.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-app>
</template>

<!--suppress JSUnresolvedVariable -->
<script>
import Footer from '@/components/Interface/Footer'
export default {
  name: 'App',
  components: { Footer },
  metaInfo () {
    return {
      title: 'myStage.ro',
      meta: [
        { name: 'description', content: this.$vuetify.lang.t('$vuetify.site.meta.description') },
        { property: 'og:title', content: this.$vuetify.lang.t('$vuetify.site.meta.title') },
        { property: 'og:site_name', content: this.$vuetify.lang.t('$vuetify.site.meta.siteName') },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  data: function () {
    return {
      vindeDialog: false,
      voucherDialog: false,
      aboutDialog: false,
      darkMode: false,
      appName: 'myStage.ro',
      imageLink: {
        logo: require('@/assets/logo.png'),
        logoSmall: require('@/assets/mystage-web-small.png')
      },
      userMenu: null,
      menu: [],
      langs: []
    }
  },
  methods: {
    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    handleDarkMode () {
      // next tick required cuz vue renders first then thinks:)
      // may be solvable if setting the theme in the store. MEH
      this.$nextTick(() => {
        if (process.browser) {
          if (this.darkMode === true) {
            this.$vuetify.theme.dark = true
            localStorage.setItem('DarkModeThemeStatus', 'true')
          } else if (this.darkMode === false) {
            this.$vuetify.theme.dark = false
            localStorage.setItem('DarkModeThemeStatus', 'false')
          }
        }
      })
    },
    saveCookiePrefs () {
      localStorage.setItem('cookieGdpr', 'true')
    }
  },
  watch: {
    darkMode () {
      this.handleDarkMode()
    }
  },
  computed: {

    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    account: function () {
      return this.$store.state.user.account.info
    },
    isLoggedIn: function () {
      return this.$store.state.user.isAuthenticated
    },
    hideNavbar: function () {
      return typeof this.$router.currentRoute.meta.hideNavbar === 'undefined' ? false : this.$router.currentRoute.meta.hideNavbar
    },
    height: function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 600
        case 'xl':
          return 800
      }
      return 10
    }
  },
  created () {
    this.darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    // const userPrefersLight = window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches
    if (process.browser) {
      if (localStorage.getItem('DarkModeThemeStatus')) {
        this.darkMode = localStorage.getItem('DarkModeThemeStatus') === 'true'
      } else {
        this.handleDarkMode()
      }
      // handle cookies
      if (localStorage.getItem('cookieGdpr') === 'false' || localStorage.getItem('cookieGdpr') === null) {
        const msg = this.$vuetify.lang.t('$vuetify.site.cookiePolicy')
        // noinspection JSCheckFunctionSignatures

        this.$toast(msg, {
          containerClassName: 'primary',
          closeOnClick: false,
          onClose: () => { this.saveCookiePrefs() },
          closeButton: 'a',
          position: 'bottom-center',
          timeout: false
        })
      }
    }
  },
  mounted: function () {
    this.$store.dispatch('init')
    this.$gtag.event('enterQueue', { method: 'Local', event_category: 'Queue' })
  }
}
</script>
<style>
.Vue-Toastification__toast--default.primary {
  background-color: #496F5D;
  width: 100% !important;
}
</style>
