export default {
  install (Vue) {
    Vue.prototype.$urlHelper = {
      cdnUrl: [
        'https://mystage-static-1.starter.ro/',
        'https://mystage-static-2.starter.ro/',
        'https://mystage-static-3.starter.ro/',
        'https://mystage-static-4.starter.ro/',
        'https://mystage-static-5.starter.ro/',
        'https://mystage-static-6.starter.ro/',
        'https://mystage-static-7.starter.ro/',
        'https://mystage-static-8.starter.ro/'
      ],
      getPlaceholderPic () {
        return 'https://mystage-static.starter.ro/files/d604dca6d9e144a4c2f0944967cc88fa71855887.jpg'
      },
      getCdn () {
        return this.cdnUrl[Math.floor(Math.random() * this.cdnUrl.length)]
      },
      getImageVariant (source, type) {
        // TODO: add valid options
        // xsmall b0 h0 standard portrait etc
        return source.replace('.jpg', `_${type}.jpg`)
      },
      fixDiacritice (source) {
        return source.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9-_]/g, '_').replace(/-/g, '').replace(/__+/g, '_')
      }
    }
  }
}
