import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from 'vue-toastification'
import logger from './plugins/logger'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import urlHelper from '@/plugins/urlHelper'
import 'vuetify/dist/vuetify.min.css'
import 'vue-toastification/dist/index.css'
import titleMixin from './plugins/titleMixin'
import vueVimeoPlayer from 'vue-vimeo-player'
import DateTimePicker from 'vuetify-datetime-picker'

import axios from 'axios'

Vue.prototype.$axios = axios
Vue.use(require('vue-moment'))

Vue.mixin(titleMixin) // auto set title
Vue.config.productionTip = false

Vue.use(urlHelper)

Vue.use(VueGtag, {
  config: { id: 'UA-65181876-1' },
  appName: 'myStage.ro',
  pageTrackerScreenviewEnabled: true
}, router)

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 3,
  newestOnTop: true,
  position: 'top-right',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.7,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  toastClassName: 'primary',
  icon: true,
  rtl: false
})
Vue.use(VueMeta)
Vue.use(DateTimePicker)
Vue.use(vueVimeoPlayer)
new Vue({
  router,
  logger,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
