import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import Cookies from 'js-cookie'
// eslint-disable-next-line no-unused-vars
import router from '@/router'
import pathify from '@/plugins/vuex-pathify'

Vue.use(Vuex)

const BUILD_NUMBER = '1'
const VERSION_NUMBER = '1.3'
const BUILD_NAME = 'Queue'
const MANAGEMENT_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://offline-mgmt.mystage.ro'
const hasSocket = false
const io = require('socket.io-client')

// TODO: Move all of this to a client DB
const URL_CHECK_ALIVE = 'https://booking.mystage.ro/jsonpages/placebo'
const URL_FORWARD_TARGET = 'https://www.mystage.ro'
const URL_OVER_NO_OF_CLIENTS = 'https://www.mystage.ro'
const WILL_AUTO_FORWARD = true
const MAX_CONCURENT_CLIENTS = 1024
// END TODO
const state = {
  enableAutoFlow: true,
  queue: {
    startTime: null,
    currentQueue: 0,
    queueWhenJoining: 0,
    timeLeft: 0,
    myPlace: 0,
    loadValue: 0,
    loadValueNice: 0,
    canGo: false
  },
  urls: {
    forwardUrl: URL_FORWARD_TARGET,
    checkUrl: URL_CHECK_ALIVE,
    allowForward: WILL_AUTO_FORWARD
  },
  faye: false,
  versionNumber: VERSION_NUMBER,
  versionName: BUILD_NAME,
  buildNumber: BUILD_NUMBER,
  api: null,
  log: null,
  refreshTimer: null,
  prefs: {
    darkMode: false
  },
  socketClientId: null,
  socket: null
}
const getters = {
  isLoggedIn: state => state.user.isAuthenticated,
  email: state => state.user.account.email
}
const mutations = {
  setupSocket (state) {
    if (!hasSocket) return
    if (state.socket !== null) {
      state.socket.disconnect()
    }
    const socket = io(MANAGEMENT_URL, {
      reconnectionDelayMax: 10000,
      reconnect: true
    })
    socket.on('connect', () => {
      socket.emit('call', 'v1.system.init', { type: 'web', version: state.versionNumber }, (err, res) => {
        if (err) {
          console.log(err)
        }
        state.socketClientId = res.socketId
      })
    })
    socket.on('serverBroadcast', (version, msg, action, detail) => {
      switch (action) {
        case 'message':
          Vue.prototype.$toast(`SYSTEM [${version}]: ${msg}`, { timeout: false, position: 'top-center', closeOnClick: true, closeButton: 'a' })
          break
      }
    })
    socket.on('order', () => {})
    socket.connect()
    state.socket = socket
  },
  toggleFaye (state) {
    state.faye = !state.faye
  },
  toggleCanGo (state, value) {
    state.queue.canGo = value
  },
  setConnections (state, queue) {
    if (state.queue.startTime === null) {
      state.queue.currentQueue = queue
      state.queue.myPlace = queue
      state.queue.queueWhenJoining = queue
      state.queue.startTime = moment()
    } else {
      // daca nu e start time
      if (queue > state.queue.currentQueue) {
        state.queue.currentQueue += queue - state.queue.currentQueue
      } else if (queue === state.queue.currentQueue) {
        // a plecat cineva si a iesit cineva
        state.queue.myPlace--
      } else {
        state.queue.currentQueue--
        state.queue.myPlace--
      }
    }
    state.queue.timeLeft = (state.queue.myPlace - 1) * Math.floor(Math.random() * 17 + 3) + 5 // pana la 17 secunde per persona + 5 secunde redirect
    const tmpTime = (new Date(state.queue.timeLeft * 1000)).toUTCString()
    state.queue.timeLeftNice = {
      minute: moment(tmpTime).format('m'),
      secunde: moment(tmpTime).format('s')
    }
    if (state.queue.timeLeft <= 0) { state.queue.timeLeft = 0 }
    if (state.queue.myPlace === 2) {
      state.queue.loadValue = 98
    } else if (state.queue.myPlace < 2) {
      state.queue.myPlace = 1
      state.queue.loadValue = 100
    }
    state.queue.loadValue = Math.floor((1 - state.queue.myPlace / state.queue.queueWhenJoining) * 100)
    if (state.queue.currentQueue > MAX_CONCURENT_CLIENTS) {
      window.location.href = URL_OVER_NO_OF_CLIENTS
    }
  },
  setDefaults (state, payload) {
    // convert them & translate
  }
}
const actions = {
  init: async ({ commit, dispatch, state }) => {
    state.log = Vue.prototype.$log
  }
}

const store = new Vuex.Store({
  mutations,
  actions,
  state,
  getters,
  plugins: [
    pathify.plugin
  ]
})

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })
