import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Home,
    name: 'Queue',
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false }
  }

]

const router = new VueRouter({

  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(
  (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin) && store.state.user.authenticated === false) {
      store.commit('setGlobalError', 'You need to log in before you can perform this action.')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      if (to.name === 'Home' && store.state.user.authenticated) {
        next('/dashboard')
      }
      next()
    }
  }
)
router.afterEach(
  (to, from) => {
    // clear all the homepage load timers
    clearTimeout(window.loadTimer)
  }
)

export default router
/* clearTimeout(window.loadTimer)
    console.log(this.$router)

 */
