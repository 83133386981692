import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'

import ro from '@/translations/ro'
import en from '@/translations/en'

Vue.use(Vuetify, { directives: { ripple } })

export default new Vuetify({
  lang: {
    locales: { ro, en },
    current: 'ro'
  },
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' }
  },
  rtl: false,
  theme: {
    light: true,
    themes: {
      dark: {
        primary: '#104F55',
        secondary: '#8B94A3',
        accent: '#121212',
        error: '#DB5461',
        info: '#666',
        success: '#03DAC6',
        warning: '#303F9F'
      },
      light: {
        primary: '#a6093d',
        secondary: '#496F5D',
        accent: '#FFFFFF',
        error: '#FF715B',
        info: '#e2f5f5',
        success: '#4cae1f',
        warning: '#DB5461'
      }
    }
  }
})
