import Vue from 'vue'
import VueLogger from 'vuejs-logger'
const isProd = process.env.NODE_ENV === 'production'

const options = {
  isEnabled: true,
  logLevel: isProd ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}
Vue.use(VueLogger, options)

export default Vue
